<template>
  <div>
    <VTitle :title="$t('app.grade_distribution')" class="overview-title" />

    <VCard>
      <template #content>
        <HorizontalBar :data-sets="dataSets" :labels="labels" />
      </template>
    </VCard>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Components
import HorizontalBar from "@/components/charts/horizontal-bar-chart/Main";
import VCard from "@/components/VCard";
import VTitle from "@/components/VTitle";

export default {
  components: {
    HorizontalBar,
    VCard,
    VTitle
  },
  props: {
    gradeDistribution: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Computed
    const labels = computed(() => {
      return Object.keys(props.gradeDistribution).map(key => key);
    });

    const data = computed(() => {
      return Object.values(props.gradeDistribution).map(value => value);
    });

    const dataSets = computed(() => {
      return [
        {
          label: t("app.grade_distribution"),
          barPercentage: 1,
          barThickness: 10,
          maxBarThickness: 10,
          minBarThickness: 2,
          data: data.value,
          backgroundColor: "#3160D8"
        }
      ];
    });

    return {
      labels,
      dataSets
    };
  }
};
</script>
