<template>
  <div>
    <VTitle :title="$t('app.questions', 2)" class="overview-title" />

    <VAlert v-if="questions.length === 0" :text="$t('app.no_questions')" />

    <div v-else>
      <VCard
        v-for="(question, qIndex) in questions"
        :key="question.id"
        :class="{ 'mb-8': qIndex !== questions.length - 1 }"
      >
        <template #title>
          {{ trimHTML(getText(question.texts, "details")) }}
        </template>

        <template v-if="question.answers.length" #content>
          <div class="grid grid-cols-3">
            <div class="col-span-2">
              <div
                v-for="(answer, aIndex) in question.answers"
                :key="answer.id"
                class="flex justify-between items-center"
                :class="{ 'mb-4': aIndex !== question.answers.length - 1 }"
              >
                <div class="flex items-center">
                  <div
                    class="self-center rounded-full w-3 h-3 mr-2"
                    :style="{ 'background-color': colors[qIndex][aIndex] }"
                  />

                  {{ getText(answer.texts, "name") }}

                  <VAction
                    v-if="answer.correct_answer === 'Yes'"
                    icon="correct"
                    color="text-theme-9"
                  />
                </div>

                <div>
                  {{ answer.number_of_selections }}
                </div>
              </div>
            </div>

            <div>
              <PieChart
                v-if="hasData(question)"
                :labels="getLabels(question)"
                :chart-data="getData(question)"
                :background-color="colors[qIndex]"
                :hover-background-color="colors[qIndex]"
              />
            </div>
          </div>
        </template>
      </VCard>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
// Components
import VCard from "@/components/VCard";
import VTitle from "@/components/VTitle";
import VAlert from "@/components/VAlert";
import VAction from "@/components/tables/VAction";
import PieChart from "@/components/charts/pie-chart/Main";

export default {
  components: {
    VAlert,
    VCard,
    VAction,
    PieChart,
    VTitle
  },
  props: {
    questions: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Composables
    const { getText } = useTexts();
    const { getRandomColors } = useColor();
    const { trimHTML } = useDisplay();

    // Computed
    const colors = computed(() => {
      return props.questions.map(question => {
        return getRandomColors(question.answers?.length ?? 1);
      });
    });

    // Methods
    // eslint-disable-next-line
    const getLabels = question => question?.answers?.map(answer => getText(answer.texts, "name")) ?? [];
    // eslint-disable-next-line
    const getData = question => question?.answers?.map(answer => answer.number_of_selections) ?? [];
    // eslint-disable-next-line
    const hasData = question => getData(question).filter(answer => answer).length > 0;

    return {
      colors,
      getData,
      getLabels,
      hasData,
      // useTexts
      getText,
      // useDisplay
      trimHTML
    };
  }
};
</script>
