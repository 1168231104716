<template>
  <div>
    <VTitle :title="$t('app.overview')" class="section-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else class="grid grid-cols-2 lg:grid-cols-3 gap-8">
      <div class="col-span-2">
        <Questions :questions="data.questions" />
      </div>

      <div class="col-span-2 lg:col-span-1">
        <VRatings
          :rating-count="data.evaluation?.rating_count ?? 0"
          :rating="data.evaluation?.rating ?? 0"
          class="mb-8"
        />

        <GradeDistribution
          :grade-distribution="data.grade_distribution"
          class="mb-8"
        />

        <Statistics :statistics="data.statistics" class="mb-8" />

        <VStatisticsCard
          hide-legend
          class="mb-8"
          :chart-type="chartTypes.PIE"
          :statistics="data.statuses"
          :title="$t('app.statuses', 2)"
        />

        <VStatisticsCard
          hide-legend
          class="mb-8"
          :chart-type="chartTypes.PIE"
          :statistics="data.results"
          :title="$t('app.results', 2)"
        />

        <Versions :route="route" :versions="data.versions" class="mb-8" />

        <LatestFeedback :feedback="data.latest_feedback" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
// Composables
import useObservation from "@/composables/useObservation";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import VStatisticsCard from "@/components/VStatisticsCard";
import Statistics from "@/views/teaching/lessons/overview/Statistics";
import Versions from "@/components/templates/Versions";
import GradeDistribution from "./GradeDistribution";
import Questions from "./Questions";
import VRatings from "@/components/VRatings";
import LatestFeedback from "@/components/templates/LatestFeedback";
// Constants
import chartTypes from "@/constants/chartTypes";

export default {
  components: {
    VTitle,
    Statistics,
    Versions,
    GradeDistribution,
    Questions,
    LatestFeedback,
    VStatisticsCard,
    VRatings
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Composables
    const { isRequesting: isLoading, request: read } = useRequest();
    const { endpoint, route } = useObservation();

    // Data
    const data = ref({});

    // Methods
    const getOverview = async () => {
      const response = await read({
        endpoint: `${endpoint}.overview`,
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data;
    };

    // Lifecycle Hooks
    onMounted(() => {
      getOverview();
    });

    return {
      data,
      chartTypes,
      // useRequest
      isLoading,
      // useObservation
      route
    };
  }
};
</script>
